
<template>
  <div
    id="login"
    style="min-height: calc(100vh - 20px)"
    class="login-page"
    tag="section"
  >
    <b-row>
      <div class="login-box" no-body>
        <div class="login-wrapper">
            <img src="@/assets/images/venue_booking/logo.svg" id="logo_image" />
          <b-overlay :show="login_emailshow" rounded="sm">
          <b-form
            id="email_login_form"
            @submit.prevent="handleSubmitEmail"
            v-if="email_login_flag == 1"
          >
            <b-form-group>
              <b-form-input
                id="txt-email"
                type="text"
                placeholder="Enter Email or Username"
                class="mb-3"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
              ></b-form-input>
              <!-- <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.form.email.$error"
              >
                <div v-if="!$v.form.email.required">
                  Please enter your email
                </div>
               
              </b-form-invalid-feedback> -->
              <b-form-invalid-feedback id="first_name-1-live-feedback"  v-if="$v.form.email.$error">
								<span v-if="!this.$v.form.email.required " class="text-danger">
									Please enter your email
								</span>
							
							</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input
                id="txt-pwd"
                type="password"
                placeholder="Enter Password"
                class="mb-3"
                v-model="$v.form.pwd.$model"
                :state="validateState('pwd')"
              ></b-form-input>
              <!-- <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.form.pwd.$error"
              >
                <div v-if="!$v.form.pwd.required">
                  Please enter your password
                </div>
              </b-form-invalid-feedback> -->
              <b-form-invalid-feedback id="first_name-1-live-feedback"  v-if="$v.form.pwd.$error">
								<span v-if="!this.$v.form.pwd.required" class="text-danger">
									Please enter your password
								</span>
							
							</b-form-invalid-feedback>
            </b-form-group>
            <b-alert :show="loginfail_a" variant="danger" class="bt-alert">
              <a  class="alert-link d-flex align-items-center">
                <b-button variant="danger" disabled>
                  <i class="mdi mdi-alert-circle"></i>
                </b-button>
                {{login_fail}}
              </a>
            </b-alert>
            <b-form-group>
              <b-button type="submit" variant="primary" block class="mt-2">
                <i class="fas fa-sign-in-alt">&nbsp;SIGN IN</i>
              </b-button>
            </b-form-group>
            <div class="mt-2">
              <b-link to="/forgot_password" class="link">
                Forgot your password?
              </b-link>
            </div>
  
          </b-form>
          </b-overlay>


         <!-- <b-overlay :show="otp_show" rounded="sm">
          <b-form
            id="login_otp_form"
            @submit.prevent="handleSubmitOtp"
            v-if="otp_flag == 1"
          >
            <h6>Check your Inbox and enter OTP</h6>
            <br />
            <b-form-group>
              <b-form-input
                id="txt-otp"
                type="text"
                placeholder="Enter OTP"
                class="mb-3"  
                 v-model="otpform.otp"
                :state="validateStateOtp('otp')"
              ></b-form-input>
               <b-form-invalid-feedback
                id="pehrs-1-live-feedback"
                v-if="$v.otpform.otp.$error"
              >
                <div v-if="!$v.otpform.otp.required">
                  Please enter your OTP
                </div>
                <div v-if="!$v.otpform.otp.minLength || !$v.otpform.otp.maxLength || !$v.otpform.otp.numeric">
                  Please enter valid OTP
                </div>
                 
              </b-form-invalid-feedback>
            </b-form-group>
               <b-alert :show="otpfail_a" variant="danger" class="bt-alert">
              <a  class="alert-link d-flex align-items-center">
                <b-button variant="danger" disabled>
                  <i class="mdi mdi-alert-circle"></i>
                </b-button>
                {{otp_fail}}
              </a>
            </b-alert>
            <b-form-group>
              <b-button type="submit" variant="primary" block class="mt-2">
                SUBMIT OTP
              </b-button>
            </b-form-group>

            <div class="mt-3">
               <b-link @click="resendOTP()" class="link" v-if="resend_link_active === true"> Resend OTP </b-link>
               <b-link class="link" disabled v-if="resend_link_active === false" style="color: #b1abab"> Resend OTP </b-link>
            </div>
          </b-form>
          </b-overlay>-->
        </div>
      </div>
    </b-row>
  </div>
</template>
    

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength , maxLength , numeric } from "vuelidate/lib/validators";

export default {
  name: "BoxedLogin",
  mixins: [validationMixin],
  data: () => ({
    form: {
      email: "",
      pwd: "",
    },
    otpform: {
      otp: "",
    },
    checkbox: false,
    email_login_flag: 1,
    username_login_flag: 0,
    otp_flag: 0,
    flag: "",
    status_flag: localStorage.getItem("status_flag"),
    login_fail: "",
    otp_fail: "",
    loginfail_a: false,
    otpfail_a: false,
    login_emailshow: false,
    otp_show: false,
    resend_link_active : true,
    is_resend_otp : false,
    interval: null
  }),
  computed: {},
  validations: {
    form: {
      email: {
        required,
        // email,
      },
      pwd: {
        required,
       // minLength: minLength(8),
      },
    },
   otpform:{
      otp: {
        required,
         minLength: minLength(4),
         maxLength: maxLength(4),
         numeric
      }
    }
  },
  methods: {
    validateState(email) {
      const { $dirty, $error } = this.$v.form[email];
      return $dirty ? !$error : null;
    },
      validateStateOtp(otp) {
      const { $dirty, $error } = this.$v.otpform[otp];
      return $dirty ? !$error : null;
    },
    handleSubmitEmail() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.login_emailshow = true; 
        this.flag = "email";
        axios
          .post("/admin/login", {
            flag: this.flag,
            email: this.form.email,
            password: this.form.pwd,
          })
          .then((resp) => {
           // if (resp.data.status) {
             /*  this.login_emailshow = false;
              this.loginfail_a = false; 
              this.flag = "";
              // localStorage.setItem("email", this.form.email);
              localStorage.setItem("email", resp.data.email);
              localStorage.setItem("login_by", resp.data.login_by);
              this.email_login_flag = 0;
              this.otp_flag = 1; */

              /* this.is_resend_otp = true;
              if(this.is_resend_otp == true){
                this.interval = setInterval(this.resendOTP, process.env.VUE_APP_RESEND_OTP_MILLISECONDS) //number of milliseconds 30 sec
              } */
           /*  } else {
             this.login_emailshow = false;
             this.loginfail_a = true; 
             this.login_fail = resp.data.message;
            } */
            if (resp.data.status) {
            this.otp_show = false;
            this.otpfail_a = false; 
            localStorage.clear();
            this.flag = "";
            this.email_login_flag = 0;
            this.otp_flag = 1;

            localStorage.setItem("authenticated", true);
            localStorage.setItem(
              "loggedUserData",
              JSON.stringify(resp.data.user)
            );
            localStorage.setItem("token", resp.data.authorisation.token);
            localStorage.setItem("first_demo", resp.data.authorisation.first_demo);
            localStorage.setItem("welcome_message", "Yes");
            this.$store.commit("SET_USER_FNAME", resp.data.user.name);
            this.$store.commit("SET_KYC_NOTIFICATION", resp.data.user);
            this.$store.commit("SET_USER_DATA", resp.data.user);
            this.$store.commit("SET_APP_LOGO", resp.data.user_logo);
            
            this.$router.push("/dashboard", () => {
              this.$root.$refs.app.showToast(
                "success",
                resp.data.message,
              );
            });
          }
          else {
             this.login_emailshow = false;
             this.loginfail_a = true; 
             this.login_fail = resp.data.message;
            }
          });
      }
    },
    handleSubmitOtp() {
      this.$v.otpform.$touch();
      if (!this.$v.otpform.$anyError) {
      this.otp_show = false;
      this.flag = "otp";
      axios
        .post("/api/login", {
          flag: this.flag,
          // email: this.form.email,
          email: localStorage.getItem('email'),
          otp: this.otpform.otp,
        })
        .then((resp) => {
         // if (resp.data.status) {
            this.otp_show = false;
            this.otpfail_a = false; 
            localStorage.clear();
            this.flag = "";
            this.email_login_flag = 0;
            this.otp_flag = 1;

            localStorage.setItem("authenticated", true);
            localStorage.setItem(
              "loggedUserData",
              JSON.stringify(resp.data.user)
            );
            localStorage.setItem("token", resp.data.authorisation.token);
            localStorage.setItem("welcome_message", "Yes");

            this.$store.commit("SET_USER_FNAME", resp.data.user.first_name);
            this.$store.commit("SET_USER_LNAME", resp.data.user.last_name);
            this.$store.commit("SET_USER_DATA", resp.data.user);
            this.$store.commit("SET_APP_LOGO", resp.data.user_logo);

            this.$router.push("/dashboard", () => {
              this.$root.$refs.app.showToast(
                "success",
                resp.data.message,
              );
            });
        /*   } else {
            this.otp_show = false;
            this.otpfail_a = true; 
            this.otp_fail = resp.data.message;
          } */
        });
      }
    },
    resendOTP() {
      const email = localStorage.getItem("email");
      this.resend_link_active = false;
      axios
        .post("/api/resend_otp", {
          email: email,
          login_by: localStorage.getItem("login_by"),
        })
        .then((resp) => {
        if (resp.data.status) {
          this.$root.$refs.app.showToast(
            "success",
            resp.data.message
          );
          this.resend_link_active = true;
        } else {
          this.$root.$refs.app.showToast(
            "danger",
            resp.data.message
          );
        }
      });
    },
    decode_parameter()
    {
      console.log(this.$route.query.email);
       this.flag=this.$route.query.flag;
       this.form.email=this.$route.query.email;
       this.form.pwd=this.$route.query.password;
      this.handleSubmitEmail();
    },
  },
  mounted() {
this.decode_parameter();
 
  
  },
  destroyed() {
    clearInterval(this.interval)
  }
};
</script>
<style scoped>
.login-page {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#logo_image {
  width: 180px;
  /* margin-left: 90px; */
  /* height: 60px; */
  padding-bottom: 15px;
}
.login-wrapper {
  text-align: center;
}
.login-box {
  background: #fff;
  max-width: 400px;
  width: 100%;
  padding: 30px;
  margin: 8% auto;
}
@media screen and (max-width: 475px) {
  .login-box {
    max-width: 100%;
    margin: 0 30px;
  }
}
</style>